import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
        },
    },
    scales: {
        x: {
            title: {
                display: true,
                text: 'Month'
            }
        },
        y: {
            title: {
                display: true,
                text: 'Clbs'
            }
        }
    }
};


export const CLBGraph = ({ result, loading }) => {

    const data = {
        labels: [...result?.map(el => el.date)],
        datasets: [
            {
                label: '',
                data: [...result?.map(el => el.totalCLBs)],
                backgroundColor: '#ffffff',
            },
        ],
    };
    return (
        <>
            <Bar options={options} data={data} height={80} />
        </>
    );
}
